<template>
  <div v-wechat-title="this.goodsinfo.name+ '-药百万'">
    <div class="HeadBox">
      <div>
        <ShopHead :productName="productName"></ShopHead>
      </div>
    </div>
    <div  class="wraperBox"  >
        <div class="ProductDetail_left">
            <el-row>
              <div class="wraper">
                <ShopGalss class="glass"  v-if="renderFlag"  :goodsinfo="goodsinfo"></ShopGalss>
                <GoodsInfo
                  class="info"
                  v-if="renderFlag"
                  :goodsinfo="goodsinfo"
                ></GoodsInfo>
              </div>
            </el-row>
            <el-row>
              <el-col :span="24"  v-if="AssociatedFlag">

                <AssociatedDrug
                  class="AssociatedDrug"
                  v-if="renderFlag"
                  :goodsinfo="goodsinfo"
                  @ShowFun="AssociatedFun"
                ></AssociatedDrug>
              </el-col>
              <el-col :span="24">
                <DetailInfo
                  class="detailInfo"
                  v-if="renderFlag"
                  :goodsinfo="goodsinfo"
                ></DetailInfo>
              </el-col>
            </el-row>
        </div>
        <div class="ProductDetail_right">
           <DetailStoreTop  v-if="renderFlag" :postId="goodsinfo.postId"></DetailStoreTop>
        </div>
    </div>
    
  </div>
</template>

<script>
import { GetProductDetail } from "api/product.js";
// import Qs from 'qs'
const DetailStoreTop = () => import("components/product/DetailStoreTop.vue");
const ShopGalss = () => import("components/product/ShopGalss.vue");
const GoodsInfo = () => import("components/product/GoodsInfo.vue");
const ShopHead = () => import("components/product/ShopHead.vue");
const DetailInfo = () => import("components/product/DetailInfo.vue");
const AssociatedDrug = () => import("components/product/AssociatedDrug.vue");
export default {
  name: "ProductDetail",
   inject:['reload'],
  data() {
    return {
      productName:'',
      renderFlag: false,
      AssociatedFlag:true,
      goodsinfo: "",
      parameterValues: "",
      
    };
  },
  components: {
    ShopGalss,
    GoodsInfo,
    ShopHead,
    DetailInfo,
    AssociatedDrug,
    DetailStoreTop
  },
   watch: {
    //   监听路径变化，获取数据并给商品数据赋值
    "$route.query.id": function() {
        this.reload();
    },
  },
  created() {
    this.getProductDetail();
  },
  methods: {
     AssociatedFun(){
      this.AssociatedFlag=false
    },
    getProductDetail: function() {
      const parmes = this.$route.query;
       // 显示loading
     this.$loading({ fullscreen: true ,background:'#ffffff45'})
      GetProductDetail(parmes).then((res) => {
        if(res.data.code==400){
          this.$message.error(res.data.msg);
          let that=this
          setTimeout(function(){that.$router.push({name:"Home"})},500);
        }
        this.productName=res.data.data.name
        this.goodsinfo = res.data.data;
        this.renderFlag = true;
        this.parameterValues = res.data.data;
         // 关闭loading
          this.$loading().close();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.HeadBox{
      padding-top: 16px;
      background:#F6F6F6 ;
      div{
          width: 1200px;
         margin:0px auto;
      }
}
.wraperBox{
   width: 1200px;
   margin:0px auto;
   .ProductDetail_left{
     width:980px;
     float: left;
     margin-top: 20px;

   }
   .ProductDetail_right{
     width:220px;
     float: left;
     margin-top: 20px;
     height: auto;
   }
}
.el_main {
  background: #fff;
}
.wraperBox {
  .glass {
    float: left;
  }
  .info {
    float: left;
    margin-left: 20px;
    width: calc(100% - 408px);
  }
  .detailInfo {
    width:970px;
  }
}
</style>
